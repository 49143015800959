
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
  watch,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import AddCampaignMemberModel from "@/views/interactive-marketing/marketing-campaigns/AddCampaignMemberModel.vue";
import { ApiCampaign, ApiBase } from "@/core/api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import moment from "moment";
import _ from "lodash";
import { ELDatatable } from "magicbean-saas-common";
import {
  commonChangeDefaultDate,
  formatDate,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";
import PermissionCommon from "@/components/layout/PermissionCommon.vue";

export default defineComponent({
  name: "marketing-campaigns-information",
  components: {
    AddCampaignMemberModel,
    ELDatatable,
    PermissionCommon,
  },
  setup() {
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const deleteButton = ref<HTMLElement | null>(null);
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      id: route.params.id,
      name: "",
      status: "",
      parent_campaign: "",
      campaign_purpose: "",
      start_date: null,
      end_date: null,
      description: "",
      selection_mode: 0, // 自动:10|手动:20|混合:30
      mb_segmentation: [],
      coverage_scope: [],
      dedicated_group_level: [],
      created_at: null,
      updated_at: null,
      created_uid: "",
      updated_uid: "",
      __show: {
        created_user: "",
        updated_user: "",
      },
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      status: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      purpose: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      type: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      start_date: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      end_date: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      selection_mode: [
        {
          required: true,
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (value === 0 || value === 1 || value === 2) {
                reject(t("common.isRequired"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
    });

    const options = ref({
      defaultSelectionMode: 0, // 自动:10|手动:20|混合:30
      defaultMbSegmentation: [],
      defaultCoverageScope: [],
      defaultDedicatedGroupLevel: [],
      defaultEndDate: null,
      campaignMemberLoading: true,
      campaignMemberList: [],
      campaignMemberColumns: [
        {
          label: t("marketingCampaigns.influencerAccountId"),
          prop: "system_id",
        },
        {
          label: t("marketingCampaigns.type"),
          prop: "type",
          slot: true,
        },
        {
          label: t("marketingCampaigns.name"),
          prop: "name",
        },
        {
          label: t("marketingCampaigns.mobile"),
          prop: "mobile",
        },
        {
          label: t("marketingCampaigns.weChatAccount"),
          prop: "wechat_account",
        },
        {
          label: t("marketingCampaigns.assignedGroup"),
          prop: "group",
        },
        {
          label: "",
          prop: "actions",
          width: 100,
          align: "right",
          slot: true,
        },
      ],
      campaignMemberCurrentPage: 1,
      campaignMemberTotal: 0,
      campaignMemberTotalPage: 0,
      campaignMemberPageSize: 10,
      salesInteractionLoading: true,
      salesInteractionList: [],
      salesInteractionColumns: [
        {
          label: t("interactiveActivities.targetName"),
          prop: "target_name",
        },
        {
          label: t("interactiveActivities.influencer"),
          prop: "target_account",
        },
        {
          label: t("interactiveActivities.targetOwner"),
          prop: "target_owner",
        },
        {
          label: t("interactiveActivities.start"),
          prop: "start_date",
          slot: true,
        },
        {
          label: t("interactiveActivities.closeDate"),
          prop: "end_date",
          slot: true,
        },
        {
          label: t("interactiveActivities.completion"),
          prop: "degree_of_completion",
        },
        {
          label: "",
          prop: "actions",
          width: 100,
          align: "right",
          slot: true,
        },
      ],
      salesInteractionCurrentPage: 1,
      salesInteractionTotal: 0,
      salesInteractionTotalPage: 0,
      salesInteractionPageSize: 10,
      callOutLoading: true,
      callOutList: [],
      callOutColumns: [
        {
          label: t("callOutRecords.contact"),
          prop: "contact",
        },
        {
          label: t("callOutRecords.taskOwner"),
          prop: "task_owner",
        },
        {
          label: t("callOutRecords.dueDate"),
          prop: "due_date",
          slot: true,
        },
        {
          label: t("callOutRecords.actionDate"),
          prop: "action_date",
          slot: true,
        },
        {
          label: t("callOutRecords.importance"),
          prop: "importance",
        },
        {
          label: t("callOutRecords.comment"),
          prop: "comment",
          showOverflowTooltip: true,
        },
        {
          label: "",
          prop: "actions",
          width: 100,
          align: "right",
          slot: true,
        },
      ],
      callOutCurrentPage: 1,
      callOutTotal: 0,
      callOutTotalPage: 0,
      callOutPageSize: 10,
      quickInquiryLoading: true,
      quickInquiryList: [],
      quickInquiryColumns: [
        {
          label: t("quickInquiry.requestDate"),
          prop: "request_date",
          slot: true,
        },
        {
          label: t("quickInquiry.requestedBy"),
          prop: "requested_by",
        },
        {
          label: t("quickInquiry.taskOwner"),
          prop: "task_owner",
        },
        {
          label: t("quickInquiry.request"),
          prop: "request",
          showOverflowTooltip: true,
        },
        {
          label: t("quickInquiry.quote"),
          prop: "quote",
          showOverflowTooltip: true,
        },
        {
          label: t("quickInquiry.quoteAmount"),
          prop: "quote_amount",
        },
        {
          label: "",
          prop: "actions",
          width: 100,
          align: "right",
          slot: true,
        },
      ],
      quickInquiryCurrentPage: 1,
      quickInquiryTotal: 0,
      quickInquiryTotalPage: 0,
      quickInquiryPageSize: 10,
    });

    const modelId = ref(0);

    const campaignPurposeOptions = ref([]);
    const campaignTypeOptions = ref([]);
    const dedicatedGroupLevelOptions = ref([]);
    const dedicatedGroupLevelLoading = ref<boolean>(false);
    const campaignOptions = ref([]);
    const campaignLoading = ref<boolean>(false);

    // watch(
    //   () => [
    //     formData.value.selection_mode,
    //     formData.value.mb_segmentation,
    //     formData.value.dedicated_group_level,
    //     formData.value.coverage_scope,
    //   ],
    //   (
    //     [newMode, newSegmentation, newGroup, newScope],
    //     [oldMode, oldSegmentation, oldGroup, oldScope]
    //   ) => {
    //     if (newMode || newSegmentation || newGroup || newScope) {
    //       // searchItemsMemberList();
    //       // searchItemsSalesInteractionList();
    //     }
    //   }
    // );

    const getInfluencerGroupSourceData = async () => {
      dedicatedGroupLevelLoading.value = true;
      const { data } = await ApiBase.getInfluencerGroupSourceData({
        max_item: "all",
      });
      dedicatedGroupLevelLoading.value = false;
      if (data.code == 0) {
        dedicatedGroupLevelOptions.value = data.data;
      }
    };

    const getDropdownOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: [
          "distribution_campaign_purpose",
          "distribution_campaign_type",
        ],
      });
      if (data.code == 0) {
        campaignPurposeOptions.value =
          data.data.distribution_campaign_purpose.items;
        campaignTypeOptions.value = data.data.distribution_campaign_type.items;
      }
    };

    const getCampaignsSourceData = async () => {
      campaignLoading.value = true;
      const { data } = await ApiCampaign.getCampaignsSourceData({
        id: route.params.id,
      });
      campaignLoading.value = false;
      if (data.code == 0) {
        campaignOptions.value = data.data;
      }
    };

    const getCampaignInfo = async () => {
      loading.value = true;
      const { data } = await ApiCampaign.getCampaignInfo({
        id: route.params.id,
      });
      loading.value = false;
      if (data.code == 0) {
        formData.value = data.data;
        options.value.defaultSelectionMode = data.data.selection_mode;
        options.value.defaultMbSegmentation = data.data.mb_segmentation;
        options.value.defaultCoverageScope = data.data.mb_segmentation;
        options.value.defaultDedicatedGroupLevel =
          data.data.dedicated_group_level;
        options.value.defaultEndDate = data.data.end_date;
        getUpdateMemberList();
        getUpdateSalesInteractionList();
        getUpdateCallOutList();
        getUpdateQuickInquiryList();
      }
    };

    const handleAddModel = () => {
      modelId.value = 0;
    };

    const updateModelList = () => {
      getCampaignMemberList();
    };

    const getCampaignMemberList = async () => {
      options.value.campaignMemberLoading = true;
      const { data } = await ApiCampaign.getCampaignMemberList({
        campaign_id: route.params.id,
        account_selected_mode: options.value.defaultSelectionMode,
        account_mb_segmentation: options.value.defaultMbSegmentation,
        account_group: options.value.defaultDedicatedGroupLevel,
        account_scope: options.value.defaultCoverageScope,
        page: options.value.campaignMemberCurrentPage,
        page_size: options.value.campaignMemberPageSize,
      });
      options.value.campaignMemberLoading = false;
      if (data.code == 0) {
        options.value.campaignMemberList = data.data.items;
        options.value.campaignMemberTotal = data.data.total;
        options.value.campaignMemberTotalPage = data.data.total_page;
      }
    };

    const getUpdateMemberList = () => {
      options.value.campaignMemberCurrentPage = 1;
      getCampaignMemberList();
    };

    const debounceSearchMemberList = _.debounce(getUpdateMemberList, 1000);

    const searchItemsMemberList = () => {
      debounceSearchMemberList();
    };

    const handleDeleteCampaignMemberModel = (row) => {
      Swal.fire({
        text: "Are you sure you would like to delete it?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          options.value.campaignMemberLoading = true;
          const { data } = await ApiCampaign.deleteCampaignMember({
            account_id: row.id,
            campaign_id: route.params.id,
          });
          options.value.campaignMemberLoading = false;
          if (data.code == 0) {
            Swal.fire({
              text: "Deleted successfully!",
              icon: "success",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              updateModelList();
            });
          }
        }
      });
    };

    const setCampaignMemberPerPage = (val) => {
      options.value.campaignMemberPageSize = val;
      getCampaignMemberList();
    };

    const handleCampaignMemberCurrentChange = (val: number) => {
      options.value.campaignMemberCurrentPage = val;
      getCampaignMemberList();
    };

    const getSalesInteractionList = async () => {
      options.value.salesInteractionLoading = true;
      const { data } = await ApiCampaign.getSalesInteractionList({
        campaign_id: route.params.id,
        account_selected_mode: options.value.defaultSelectionMode,
        account_mb_segmentation: options.value.defaultMbSegmentation,
        account_group: options.value.defaultDedicatedGroupLevel,
        account_scope: options.value.defaultCoverageScope,
        page: options.value.salesInteractionCurrentPage,
        page_size: options.value.salesInteractionPageSize,
      });
      options.value.salesInteractionLoading = false;
      if (data.code == 0) {
        options.value.salesInteractionList = data.data.items;
        options.value.salesInteractionTotal = data.data.total;
        options.value.salesInteractionTotalPage = data.data.total_page;
      }
    };

    const getUpdateSalesInteractionList = () => {
      options.value.salesInteractionCurrentPage = 1;
      getSalesInteractionList();
    };

    const debounceSearchSalesInteractionList = _.debounce(
      getUpdateSalesInteractionList,
      1000
    );

    const searchItemsSalesInteractionList = () => {
      debounceSearchSalesInteractionList();
    };

    const setSalesInteractionPerPage = (val) => {
      options.value.salesInteractionPageSize = val;
      getSalesInteractionList();
    };

    const handleSalesInteractionCurrentChange = (val: number) => {
      options.value.salesInteractionCurrentPage = val;
      getSalesInteractionList();
    };

    const getCallOutList = async () => {
      options.value.callOutLoading = true;
      const { data } = await ApiCampaign.getCallOutList({
        campaign_id: route.params.id,
        account_selected_mode: options.value.defaultSelectionMode,
        account_mb_segmentation: options.value.defaultMbSegmentation,
        account_group: options.value.defaultDedicatedGroupLevel,
        account_scope: options.value.defaultCoverageScope,
        page: options.value.callOutCurrentPage,
        page_size: options.value.callOutPageSize,
      });
      options.value.callOutLoading = false;
      if (data.code == 0) {
        options.value.callOutList = data.data.items;
        options.value.callOutTotal = data.data.total;
        options.value.callOutTotalPage = data.data.total_page;
      }
    };

    const getUpdateCallOutList = () => {
      options.value.callOutCurrentPage = 1;
      getCallOutList();
    };

    const debounceSearchCallOutList = _.debounce(getUpdateCallOutList, 1000);

    const searchItemsCallOutList = () => {
      debounceSearchCallOutList();
    };

    const setCallOutPerPage = (val) => {
      options.value.callOutPageSize = val;
      getCallOutList();
    };

    const handleCallOutCurrentChange = (val: number) => {
      options.value.callOutCurrentPage = val;
      getCallOutList();
    };

    const getQuickInquiryList = async () => {
      options.value.quickInquiryLoading = true;
      const { data } = await ApiCampaign.getQuickInquiryList({
        campaign_id: route.params.id,
        account_selected_mode: options.value.defaultSelectionMode,
        account_mb_segmentation: options.value.defaultMbSegmentation,
        account_group: options.value.defaultDedicatedGroupLevel,
        account_scope: options.value.defaultCoverageScope,
        page: options.value.quickInquiryCurrentPage,
        page_size: options.value.quickInquiryPageSize,
      });
      options.value.quickInquiryLoading = false;
      if (data.code == 0) {
        options.value.quickInquiryList = data.data.items;
        options.value.quickInquiryTotal = data.data.total;
        options.value.quickInquiryTotalPage = data.data.total_page;
      }
    };

    const getUpdateQuickInquiryList = () => {
      options.value.quickInquiryCurrentPage = 1;
      getQuickInquiryList();
    };

    const debounceSearchQuickInquiryList = _.debounce(
      getUpdateQuickInquiryList,
      1000
    );

    const searchItemsQuickInquiryList = () => {
      debounceSearchQuickInquiryList();
    };

    const setQuickInquiryPerPage = (val) => {
      options.value.quickInquiryPageSize = val;
      getQuickInquiryList();
    };

    const handleQuickInquiryCurrentChange = (val: number) => {
      options.value.quickInquiryCurrentPage = val;
      getQuickInquiryList();
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }

          ApiCampaign.updateCampaign(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  getCampaignInfo();
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const deleteMarketingCampaign = () => {
      Swal.fire({
        text: "Are you sure you would like to deactivate your Marketing Campaigns?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (deleteButton.value) {
            deleteButton.value.setAttribute("data-kt-indicator", "on");
          }
          loading.value = true;
          ApiCampaign.deleteCampaign({ id: route.params.id })
            .then(({ data }) => {
              loading.value = false;
              deleteButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                Swal.fire({
                  text: "Your Marketing Campaigns has been deactivated!",
                  icon: "success",
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.push({ name: "marketing-campaigns" });
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    };

    const statusClass = (status) => {
      let statusClass = "";
      switch (status) {
        case 10:
          statusClass = "badge-light-danger";
          break;
        case 20:
          statusClass = "badge-light-warning";
          break;
        case 30:
          statusClass = "badge-light-success";
          break;
        default:
          statusClass = "badge-light-primary";
      }
      return statusClass;
    };

    const isEndDate = computed(() => {
      if (
        options.value.defaultEndDate !== "" &&
        options.value.defaultEndDate !== null &&
        options.value.defaultSelectionMode != 0
      ) {
        const nowDate = moment(new Date(), "YYYY-MM-DD").valueOf();
        const endDate = moment(options.value.defaultEndDate)
          .add(1, "d")
          .utc()
          .valueOf();
        if (endDate < nowDate) {
          return true;
        }
      }
      return false;
    });

    onBeforeMount(() => {
      getCampaignInfo();
      getDropdownOptions();
      getCampaignsSourceData();
      getInfluencerGroupSourceData();
    });

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route, router);
    });

    return {
      t,
      formatDate,
      formatDateTime,
      commonChangeDefaultDate,
      loading,
      formData,
      rules,
      options,
      formRef,
      submitButton,
      deleteButton,
      campaignPurposeOptions,
      campaignTypeOptions,
      dedicatedGroupLevelOptions,
      dedicatedGroupLevelLoading,
      campaignOptions,
      campaignLoading,
      modelId,
      submit,
      getCampaignInfo,
      deleteMarketingCampaign,
      handleAddModel,
      updateModelList,
      handleDeleteCampaignMemberModel,
      setCampaignMemberPerPage,
      handleCampaignMemberCurrentChange,
      setSalesInteractionPerPage,
      handleSalesInteractionCurrentChange,
      setCallOutPerPage,
      handleCallOutCurrentChange,
      setQuickInquiryPerPage,
      handleQuickInquiryCurrentChange,
      statusClass,
      isEndDate,
    };
  },
});
